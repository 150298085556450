import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { CustomSearch } from '@components/modules/custom-search';
import { FilterSelection } from '@components/modules/filter-selection';
import { FilterStatus } from '@components/modules/filter-status';
import { CustomPagination } from '@components/modules/pagination';
import { PAGE_SIZE } from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { AppSubscriptionStatusEnum } from '@enums/app-subscription-status.enum';
import { IMarketplace } from '@interfaces/marketplace';
import {
    CreateButton,
    DeleteButton,
    getDefaultSortOrder,
    mapAntdSorterToCrudSorting,
    ShowButton,
} from '@refinedev/antd';
import {
    LogicalFilter,
    useDelete,
    useList,
    usePermissions,
    useTable,
    useTranslate,
} from '@refinedev/core';
import { formatDate } from '@utils/resource';
import { Dropdown, Menu, notification, Popconfirm, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
const LIST_FILTER_STATUS = [
    {
        label: 'Active',
        value: AppSubscriptionStatusEnum.Active,
    },
    {
        label: 'In Complete',
        value: AppSubscriptionStatusEnum.InComplete,
    },
    {
        label: 'In Complete Expired',
        value: AppSubscriptionStatusEnum.InCompleteExpired,
    },
    {
        label: 'Trailing',
        value: AppSubscriptionStatusEnum.Trailing,
    },
    {
        label: 'Cancelled',
        value: AppSubscriptionStatusEnum.Cancelled,
    },
    {
        label: 'Open',
        value: AppSubscriptionStatusEnum.Open,
    },
];

const APPLICATION_RESOURCE_URL = 'v1/application';

export const SaSubscriptionCrmIndex: React.FC = () => {
    const translate = useTranslate();
    const [optionsApplications, setOptionsApplications] = useState<
        { label: string; value: string }[]
    >([]);
    const { data: permissions } = usePermissions<string[]>();

    const {
        setFilters,
        filters,
        tableQueryResult,
        current,
        setCurrent,
        pageSize,
        setPageSize,
        sorters,
        setSorters,
    } = useTable<IMarketplace.IAppSubscription>({
        resource: 'v1/application-subscriptions',
        sorters: {
            initial: [
                {
                    field: 'currentPeriodEnd',
                    order: 'asc',
                },
            ],
        },
        pagination: {
            pageSize: PAGE_SIZE,
        },
        syncWithLocation: true,
    });

    const { data: listApplications } = useList<IMarketplace.IApplication>({
        resource: `${APPLICATION_RESOURCE_URL}`,
        config: {
            hasPagination: false,
            filters: [
                {
                    field: 'includeUnpublish',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
    });

    const { mutate: deleteAction, isLoading: isDeleting } = useDelete();

    useEffect(() => {
        if (listApplications?.data.length) {
            const options = listApplications.data.map((app) => {
                return {
                    label: app.title,
                    value: app.id,
                };
            });
            setOptionsApplications(options);
        }
    }, [listApplications]);

    const cancelSubscription = (item: IMarketplace.IAppSubscription) => {
        if (!item?.id) return;

        deleteAction(
            {
                resource: `v1/application-subscriptions/manual-cancel`,
                id: item.id,
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: () => {
                    notification.success({
                        message: translate('subscriptions.messages.cancel_subscription_success'),
                    });

                    tableQueryResult.refetch();
                    return;
                },

                onError: () => {
                    notification.error({
                        message: translate('subscriptions.messages.cancel_subscription_failed'),
                    });
                },
            },
        );
    };

    const menu = (id: string, record: IMarketplace.IAppSubscription) => {
        return (
            <Menu mode="vertical">
                <Menu.Item
                    key="1"
                    icon={
                        <ShowButton
                            resource="report_subscription"
                            recordItemId={record.id}
                            children={translate('subscriptions.action.viewBillingHistory')}
                            className="icon-action-subscription-list"
                        />
                    }
                ></Menu.Item>
                {AppSubscriptionStatusEnum.Cancelled !== record.status ? (
                    !record.cancelAtPeriodEnd || record.isManual ? (
                        <Menu.Item key="2">
                            <Popconfirm
                                key="delete"
                                okText={translate('subscriptions.action.confirm')}
                                cancelText={translate('subscriptions.action.cancel')}
                                okType="danger"
                                title={translate(
                                    'subscriptions.messages.cancel_subscription_confirm',
                                )}
                                onConfirm={(e): void => {
                                    e?.preventDefault();
                                    cancelSubscription(record);
                                }}
                            >
                                <DeleteButton
                                    loading={isDeleting}
                                    resource="report_subscription"
                                    recordItemId={record.id}
                                    children={translate('subscriptions.action.cancel_subscription')}
                                    className="icon-action-subscription-list"
                                />
                            </Popconfirm>
                        </Menu.Item>
                    ) : null
                ) : null}
            </Menu>
        );
    };

    const onChangeFilter = (event: any) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === 'filter');
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = event.target.value;
            } else {
                newFilter.push({
                    field: 'filter',
                    operator: 'eq',
                    value: event.target.value,
                });
            }

            return newFilter;
        });

        setCurrent(1);
    };

    const onChangeStatus = (status: string) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === 'status');
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = status;
            } else {
                newFilter.push({
                    field: 'status',
                    operator: 'eq',
                    value: status,
                });
            }
            return newFilter;
        });

        setCurrent(1);
    };

    const onChangeApplication = (value: string) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex(
                (item) => (item as LogicalFilter).field === 'applicationId',
            );
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = value;
            } else {
                newFilter.push({
                    field: 'applicationId',
                    operator: 'eq',
                    value: value,
                });
            }
            return newFilter;
        });

        setCurrent(1);
    };

    const onChangeTable = (
        _: TablePaginationConfig,
        __: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    return (
        <>
            <section className="item-list-container sa-subscription-page-wrapper ">
                <div className="list-header">
                    <div className="filter-wrapper">
                        <CustomSearch
                            placeholder="Search Tenant"
                            className={'search-item custom-width'}
                            onChange={(event: any) => onChangeFilter(event)}
                            allowClear={true}
                            defaultValue={filters?.find((f: any) => f.field === 'filter')?.value}
                        />
                        <FilterStatus
                            placeholder="Select status"
                            listStatus={LIST_FILTER_STATUS}
                            className="search-status mr-8"
                            defaultValue={filters?.find((f: any) => f.field === 'status')?.value}
                            onChangeStatus={(status: string) => onChangeStatus(status)}
                        />
                        <FilterSelection
                            placeholder="Select Application"
                            listValue={optionsApplications}
                            className="search-applications"
                            defaultValue={
                                filters?.find((f: any) => f.field === 'applicationId')?.value
                            }
                            onChangeValue={(appId: string) => onChangeApplication(appId)}
                        />
                    </div>
                    {(permissions || []).includes(PERMISSIONS.REPORT_SUBSCRIPTION_CREATE) ? (
                        <CreateButton icon={false} className="btn-add-new" type="primary">
                            <PlusOutlined />
                            {translate('add_new', { ns: 'common' })}
                        </CreateButton>
                    ) : null}
                </div>
                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            pagination={false}
                            dataSource={tableQueryResult?.data?.data}
                            rowKey="id"
                            loading={tableQueryResult.isLoading}
                            onChange={onChangeTable}
                        >
                            <Table.Column
                                sorter
                                title={<>{translate('subscriptions.expiredDate')}</>}
                                dataIndex="currentPeriodEnd"
                                key="currentPeriodEnd"
                                defaultSortOrder={getDefaultSortOrder('currentPeriodEnd', sorters)}
                                render={(item, _) => {
                                    return <p className="table-tbody-text">{formatDate(item)}</p>;
                                }}
                            />
                            <Table.Column
                                title={<>{translate('subscriptions.applicationName')}</>}
                                dataIndex="application"
                                key="application"
                                render={(application, _) => {
                                    return <p className="table-tbody-text">{application?.title}</p>;
                                }}
                            />
                            <Table.Column
                                title={<>{translate('subscriptions.tenantName')}</>}
                                dataIndex="tenant"
                                key="tenant"
                                render={(tenant, _) => {
                                    return <p className="table-tbody-text">{tenant?.name}</p>;
                                }}
                            />
                            <Table.Column
                                title={<>{translate('subscriptions.statusTitle')}</>}
                                dataIndex="status"
                                key="status"
                                render={(status, _) => {
                                    return (
                                        <p className="table-tbody-text">
                                            {translate(
                                                'subscriptions.subscriptionStatus.' +
                                                    status.toString(),
                                            )}
                                        </p>
                                    );
                                }}
                            />

                            <Table.Column
                                title={<>{translate('subscriptions.payment_method.title')}</>}
                                dataIndex="isManual"
                                key="tenant"
                                render={(isManual, _, __) => {
                                    return (
                                        <p className="table-tbody-text">
                                            {isManual
                                                ? translate('subscriptions.payment_method.manual')
                                                : translate('subscriptions.payment_method.credit')}
                                        </p>
                                    );
                                }}
                            />

                            <Table.Column
                                dataIndex="id"
                                key="action"
                                render={(id, record: IMarketplace.IAppSubscription) => (
                                    <div className="flex justify-between items-center">
                                        <Dropdown
                                            overlay={menu(id, record)}
                                            placement="bottomRight"
                                            className="w-8 ml-auto"
                                            arrow
                                            overlayClassName="contact-dropdown-container"
                                            trigger={['click']}
                                        >
                                            <div className="">
                                                <img
                                                    src="/images/icons/dots-vertical.svg"
                                                    alt="more"
                                                    className="cursor-pointer"
                                                />
                                            </div>
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </Table>
                    </div>
                </div>
                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        current={current}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </section>
        </>
    );
};
