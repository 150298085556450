import './styles.scss';

import { AntDesignOutlined, PlusOutlined } from '@ant-design/icons';
import {
    CreateButton,
    DeleteButton,
    getDefaultSortOrder,
    mapAntdSorterToCrudSorting,
    ShowButton,
} from '@refinedev/antd';
import { LogicalFilter, useGetIdentity, usePermissions, useTable } from '@refinedev/core';
import { Avatar, Dropdown, Menu, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';

import { CustomSearch } from '../../components/modules/custom-search';
import { FilterStatus } from '../../components/modules/filter-status';
import { CustomPagination } from '../../components/modules/pagination';
import { PAGE_SIZE } from '../../constants/index.constant';
import { PERMISSIONS } from '../../constants/permission';
import { QuoteStatus } from '../../enums/quote-status.enum';
import { UserTypeEnum } from '../../enums/user-type.enum';
import { IOrder } from '../../interfaces/order';
import { IUser } from '../../interfaces/user';
import { convertPrice, formatDate, getPublicMediaUrl } from '../../utils/resource';

export const OrderIndex: React.FC = () => {
    const { t } = useTranslation(['quote']);

    const LIST_FILTER_STATUS = [
        {
            label: t(`quotes.status.${QuoteStatus.Paid}`),
            value: QuoteStatus.Paid.toString(),
        },
        {
            label: t(`quotes.status.${QuoteStatus.Pending}`),
            value: QuoteStatus.Pending.toString(),
        },
        {
            label: t(`quotes.status.${QuoteStatus.Void}`),
            value: QuoteStatus.Void.toString(),
        },
    ];

    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();

    const {
        setFilters,
        filters,
        setSorters,
        sorters,
        tableQueryResult,
        setCurrent,
        current,
        setPageSize,
        pageSize,
    } = useTable<IOrder.OrderList>({
        resource: 'v1/orders',
        sorters: {
            initial: [
                {
                    field: 'incrementId',
                    order: 'desc',
                },
            ],
        },
        pagination: {
            pageSize: PAGE_SIZE,
            current: 1,
        },
        syncWithLocation: true,
    });

    const { data: permissionsData } = usePermissions<string[]>();

    const menu = (id: string, record: IOrder.OrderList) => (
        <Menu mode="vertical">
            <Menu.Item
                key="0"
                icon={
                    <ShowButton
                        recordItemId={id}
                        style={{
                            padding: '5px 12px',
                            margin: '0px',
                        }}
                    />
                }
                style={{ padding: 0 }}
            ></Menu.Item>

            {(permissionsData || []).indexOf(PERMISSIONS.WL_ORDER_DELETE) >= 0 &&
                record.status === QuoteStatus.Pending &&
                !record?.quoteId && (
                    <Menu.Item
                        key="1"
                        style={{ padding: 0 }}
                        icon={
                            <DeleteButton
                                successNotification={{
                                    message: 'Successfully deleted',
                                    type: 'success',
                                }}
                                resource="v1/orders"
                                recordItemId={id}
                                style={{
                                    margin: 0,
                                    padding: '5px 16px 5px 12px',
                                }}
                                errorNotification={(err) => {
                                    const errorMsg = (err as { message: string })?.message;

                                    if (errorMsg === 'not_delete_order_create_by_plan') {
                                        return {
                                            message: t(
                                                'quotes.error.not_delete_order_create_by_plan',
                                            ),
                                            type: 'error',
                                        };
                                    }

                                    if (errorMsg === 'not_delete_order_status_paid') {
                                        return {
                                            message: t('quotes.error.not_delete_order_status_paid'),
                                            type: 'error',
                                        };
                                    }

                                    return {
                                        message: t('quotes.error.delete_order_failed'),
                                        type: 'error',
                                    };
                                }}
                            />
                        }
                    ></Menu.Item>
                )}
        </Menu>
    );

    const onChangeFilter = (event: any) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === 'filter');
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = event.target.value;
            } else {
                newFilter.push({
                    field: 'filter',
                    operator: 'eq',
                    value: event.target.value,
                });
            }
            return newFilter;
        });

        setCurrent(1);
    };

    const onChangeStatus = (status: string) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === 'status');
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = status;
            } else {
                newFilter.push({
                    field: 'status',
                    operator: 'eq',
                    value: status,
                });
            }
            return newFilter;
        });

        setCurrent(1);
    };

    const onChangeTable = (
        _: TablePaginationConfig,
        __: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    return (
        <>
            <section className="item-list-container order-page-wrapper">
                <div className="list-header">
                    <div className="filter-wrapper">
                        <CustomSearch
                            placeholder="Search orders"
                            className={'search-item'}
                            onChange={(event: any) => onChangeFilter(event)}
                            defaultValue={filters?.find((f: any) => f.field === 'filter')?.value}
                            allowClear={true}
                        />
                        <FilterStatus
                            placeholder="Select status"
                            listStatus={LIST_FILTER_STATUS}
                            className="search-status"
                            defaultValue={filters?.find((f: any) => f.field === 'status')?.value}
                            onChangeStatus={(status: string) => onChangeStatus(status)}
                        />
                    </div>
                    {(permissionsData || []).includes(PERMISSIONS.WL_ORDER_CREATE) ? (
                        <CreateButton icon={false} className="btn-add-new" type="primary">
                            <PlusOutlined />
                            {t('add_new', { ns: 'common' })}
                        </CreateButton>
                    ) : null}
                </div>

                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            dataSource={tableQueryResult.data?.data}
                            loading={tableQueryResult.isFetching}
                            onChange={onChangeTable}
                            pagination={false}
                            rowKey="id"
                            className="orders-table"
                            tableLayout="fixed"
                            scroll={{ x: '900px', y: '100%' }}
                        >
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('incrementId', sorters)}
                                title={<>{t('quotes.table.number')}</>}
                                dataIndex="incrementId"
                                key="incrementId"
                                width={80}
                                render={(text, _, index) => (
                                    <p className="table-tbody-text">{text}</p>
                                )}
                            />
                            <Table.Column
                                title={<>{t('quotes.table.customer')}</>}
                                dataIndex="contactName"
                                key="contactName"
                                defaultSortOrder={getDefaultSortOrder('contactName', sorters)}
                                sorter
                                width={300}
                                render={(row, record: IOrder.OrderList, index) => {
                                    const hasPermission = (permissionsData || []).includes(
                                        PERMISSIONS.WL_ORDER_SHOW,
                                    );
                                    return (
                                        <div className="flex justify-between items-center ">
                                            <Tooltip
                                                title={
                                                    hasPermission ? (
                                                        <ShowButton
                                                            className="p-0 table-clickable-link"
                                                            type="link"
                                                            icon={false}
                                                            recordItemId={record.id}
                                                        >
                                                            {row}
                                                        </ShowButton>
                                                    ) : (
                                                        <>{row}</>
                                                    )
                                                }
                                            >
                                                <div className="flex justify-start items-center">
                                                    <Avatar
                                                        size={40}
                                                        src={
                                                            record.contactAvatarUrl
                                                                ? getPublicMediaUrl(
                                                                      record.contactAvatarUrl,
                                                                  )
                                                                : null
                                                        }
                                                        icon={<AntDesignOutlined />}
                                                    />
                                                    <p className="table-tbody-text ml-2 item-name pd-0">
                                                        {row}
                                                    </p>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    );
                                }}
                            />
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('status', sorters)}
                                title={<>{t('quotes.table.status')}</>}
                                dataIndex="status"
                                key="status"
                                width={120}
                                render={(text, _, index) => (
                                    <p className={`table-tbody-text status-${text}`}>
                                        {t('quotes.status.' + text)}
                                    </p>
                                )}
                            />
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('amount', sorters)}
                                title={<>{t('quotes.table.amount')}</>}
                                dataIndex="amount"
                                key="amount"
                                width={120}
                                render={(text, item: IOrder.OrderList, index) =>
                                    userIdentity?.userType !== UserTypeEnum.CLIENT ||
                                    item?.createdBy !== userIdentity?.id ? (
                                        <p className="table-tbody-text">{convertPrice(text)}</p>
                                    ) : null
                                }
                            />
                            <Table.Column
                                title={<>{t('quotes.table.frequency')}</>}
                                dataIndex="frequencies"
                                key="frequencies"
                                width={200}
                                render={(frequencies, _, index) => (
                                    <p className="table-tbody-text">
                                        {frequencies
                                            .map((f: string) => t('quotes.frequency.' + f))
                                            .join(', ')}
                                    </p>
                                )}
                            />
                            <Table.Column
                                sorter
                                width={200}
                                defaultSortOrder={getDefaultSortOrder('createdAt', sorters)}
                                title={<>{t('quotes.table.date')}</>}
                                dataIndex="createdAt"
                                key="createdAt"
                                render={(text, _, __) => (
                                    <p className="table-tbody-text">{formatDate(text)}</p>
                                )}
                            />
                            <Table.Column
                                dataIndex="id"
                                key="action"
                                width={100}
                                fixed="right"
                                render={(id, record: IOrder.OrderList, __) => (
                                    <div
                                        className="flex justify-between items-center"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <Dropdown
                                            overlay={menu(id, record)}
                                            placement="bottomRight"
                                            className="w-8 ml-auto"
                                            arrow
                                            overlayClassName="contact-dropdown-container"
                                            trigger={['click']}
                                        >
                                            <div className="">
                                                <img
                                                    src="/images/icons/dots-vertical.svg"
                                                    alt="more"
                                                    className="cursor-pointer"
                                                />
                                            </div>
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </Table>
                    </div>
                </div>
                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        current={current}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </section>
        </>
    );
};
