import { InfoCircleTwoTone } from '@ant-design/icons';
import { DATE_FORMAT } from '@constants/index.constant';
import { IReport } from '@interfaces/report';
import { useApiUrl, useCustom, useList } from '@refinedev/core';
import { getLocalTime } from '@utils/date';
import { formatPriceBaseOnCurrency } from '@utils/resource';
import { Modal, Spin, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DataProviderNameEnum } from 'dataProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 as uuidv4 } from 'uuid';

import SettlementDetail from '../settlements-detail';
import s from './styles.module.scss';
export const SettlementsInformation: React.FC<{
    filter: IReport.IGetOrderStatistic;
    timeZone: string;
    isValidDay: boolean;
}> = ({ filter, timeZone, isValidDay }) => {
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;
    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);

    const { t } = useTranslation(['common']);

    const [offset, setOffset] = useState(1);
    const [limit] = useState(10);
    const [data, setData] = useState<IReport.ISettlementsReportDto[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isModalSettleVisible, setIsModalSettleVisible] = useState(false);
    const [merchantId, setMerchantId] = useState<string | null>();
    const [settlementId, setSettlementId] = useState<string | null>();

    const {
        data: fetchedData,
        isFetching,
        refetch: getSettlementsReport,
    } = useList<IReport.ISettlementsReportDto>({
        dataProviderName,
        resource: 'v1/report/settlements',
        queryOptions: { enabled: false },
        config: {
            hasPagination: false,
            filters: [
                { field: 'dateRange', operator: 'eq', value: filter.dateRange },
                { field: 'offset', operator: 'eq', value: offset },
                { field: 'limit', operator: 'eq', value: limit },
                { field: 'timeZone', operator: 'eq', value: timeZone },
            ],
        },
    });

    const {
        data: settlementDetail,
        isFetching: isFetchingSettlementDetail,
        refetch: getSettlementDetail,
    } = useCustom<IReport.ISettlementDetailReportDto>({
        dataProviderName,
        url: `${apiUrl}/v1/report/settlements/${settlementId}`,
        method: 'get',
        queryOptions: {
            enabled: false,
        },
    });

    const showSettleDetail = (settlementId: string) => {
        setSettlementId(settlementId);
        setIsModalSettleVisible(true);
    };

    const handleCancel = () => {
        setMerchantId(null);
        setSettlementId(null);
        setIsModalSettleVisible(false);
    };

    useEffect(() => {
        if (settlementId) {
            getSettlementDetail();
        }
    }, [settlementId]);

    useEffect(() => {
        if (isValidDay) {
            setLoading(true);
            setOffset(1);
            getSettlementsReport();
            setLoading(false);
        }
    }, [filter.dateRange]);

    useEffect(() => {
        if (fetchedData?.data) {
            // Determine if there is more data based on length of fetched data
            const isMoreData = fetchedData.data.length === limit;
            setHasMore(isMoreData);

            if (offset === 1) {
                // Replace data on initial load
                setData(fetchedData.data);
            } else {
                // Append data on subsequent loads
                setData((prevData) => [...prevData, ...fetchedData.data]);
            }

            setLoading(false);
        }
    }, [fetchedData]);

    const fetchMoreData = async () => {
        if (hasMore) {
            setOffset((prevOffset) => prevOffset + limit);
            await getSettlementsReport();
        }
    };

    useEffect(() => {
        if (isValidDay) {
            getSettlementsReport();
        }
    }, [offset]);

    const columns: ColumnsType<IReport.ISettlementsReportDto> = [
        {
            title: (
                <div className="table-thead-text rounded">
                    {t('settlements.created_at', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => (
                <p className="table-tbody-text">
                    {dayjs(getLocalTime(createdAt)).format(DATE_FORMAT)}
                </p>
            ),
        },

        {
            title: (
                <div className="table-thead-text rounded justify-center">
                    {t('settlements.transactions', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'transactions',
            key: 'transactions',
            render: (transactions: number) => (
                <p className="table-tbody-text text-center">{transactions}</p>
            ),
        },
        {
            title: (
                <div className="table-thead-text rounded justify-center">
                    {t('settlements.fees', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'fees',
            key: 'fees',
            render: (fees: number) => <p className="table-tbody-text text-center">{fees}</p>,
        },
        {
            title: (
                <div className="table-thead-text rounded justify-center">
                    {t('settlements.amount', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => (
                <p className="table-tbody-text text-center">{formatPriceBaseOnCurrency(amount)}</p>
            ),
        },
        {
            title: (
                <div className="table-thead-text rounded justify-center">
                    {t('settlements.fee_amount', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'feesAmount',
            key: 'feesAmount',
            render: (feesAmount: number) => (
                <p className="table-tbody-text text-center">
                    {formatPriceBaseOnCurrency(feesAmount)}
                </p>
            ),
        },
        {
            title: (
                <div className="table-thead-text rounded justify-center">
                    {t('settlements.net_amount', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'netAmount',
            key: 'netAmount',
            render: (netAmount: number) => (
                <p className="table-tbody-text text-center">
                    {formatPriceBaseOnCurrency(netAmount)}
                </p>
            ),
        },
        {
            title: (
                <div className="table-thead-text rounded justify-center">
                    {t('settlements.state.title', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'state',
            key: 'state',
            render: (state: string) => (
                <p className="table-tbody-text text-center">
                    {t(`settlements.state.${state.toLowerCase()}`, { ns: 'common' })}
                </p>
            ),
        },
        {
            dataIndex: 'Action',
            key: 'Action',
            render: (__, current) => (
                <div className="text-center">
                    <InfoCircleTwoTone
                        alt="detail"
                        className="cursor-pointer"
                        style={{ fontSize: '24px' }}
                        onClick={() => showSettleDetail(current.id)}
                    />
                </div>
            ),
            className: 'w-32',
        },
    ];

    return (
        <>
            <section className="order-statistic-container mt-5">
                <div className={s.tableWrapper} id="scrollableDiv">
                    {loading ? (
                        <div className={s.loadingContainer}>
                            <Spin />
                        </div>
                    ) : (
                        <InfiniteScroll
                            dataLength={data.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            loader={<></>}
                            scrollableTarget="scrollableDiv"
                        >
                            <Table
                                dataSource={data}
                                columns={columns}
                                pagination={false}
                                loading={isFetching}
                                rowKey={() => uuidv4()}
                            />
                        </InfiniteScroll>
                    )}
                </div>
            </section>

            {isFetchingSettlementDetail ? (
                <div className={s.loadingContainer}>
                    <Spin />
                </div>
            ) : (
                <>
                    {settlementDetail && settlementId && (
                        <Modal
                            visible={isModalSettleVisible}
                            onCancel={handleCancel}
                            okButtonProps={{ style: { display: 'none' } }}
                            width={1500}
                        >
                            <SettlementDetail
                                settlementId={settlementId}
                                settlementDetail={settlementDetail.data}
                            />
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};
