import './styles.scss';

import { Create, Edit, SaveButton } from '@refinedev/antd';
import { useTranslate } from '@refinedev/core';
import { Col, Drawer, Form, Grid, Input, Row, UploadFile } from 'antd';
import { useState } from 'react';

import { IDrawerBase } from '../../interfaces/components/drawer';
import { Hint } from '../modules/hint';
import { ImageUpload } from '../modules/image-upload';
import { Toggle } from '../modules/toggle';

export const ServiceDetails: React.FC<IDrawerBase> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    isEditMode,
}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const breakpoint = Grid.useBreakpoint();
    const initialValues: any =
        !isEditMode && !formProps.initialValues?.id
            ? { isActive: true }
            : {
                  ...formProps.initialValues,
              };
    const onChangeImage = (file: UploadFile<any>) => {
        formProps.form?.setFieldsValue({ featureImageUrl: file.response });
        if (file.status === 'uploading') {
            setLoading(true);
        } else {
            setLoading(false);
        }
    };

    const form = (
        <Form
            {...formProps}
            onFinish={(values) => {
                values.featureImageUrl =
                    values.featureImageUrl?.length > 0 ? values.featureImageUrl : null;
                formProps.onFinish && formProps.onFinish(values);
            }}
            layout="vertical"
            initialValues={{ ...initialValues }}
        >
            <Row gutter={20}>
                <Col md={16}>
                    <Form.Item
                        label={t('services.fields.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('services.fields.name_required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: t('services.fields.status_required'),
                            },
                        ]}
                        label={t('services.fields.isActive')}
                        name="isActive"
                    >
                        <Toggle />
                    </Form.Item>
                </Col>
                <Col md={8}>
                    <Form.Item
                        label={t('services.fields.images.label')}
                        name="featureImageUrl"
                        rules={[
                            {
                                required: true,
                                message: t('services.fields.image_required'),
                            },
                        ]}
                        className="image-upload-container"
                    >
                        <ImageUpload
                            width={300}
                            height={200}
                            onChange={(fileOrn) => {
                                if (fileOrn) {
                                    onChangeImage(fileOrn);
                                }
                            }}
                            uploadText="Image"
                            value={initialValues?.featureImageUrl}
                        />
                        <Hint width={300} height={200} size={5} fileType={['JPG', 'PNG']} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );

    return drawerProps.open ? (
        <Drawer
            {...drawerProps}
            width={breakpoint.md ? '700px' : '100%'}
            maskClosable={false}
            zIndex={999}
            className="service-drawer drawer-form-container"
            afterOpenChange={() => {
                formProps.form?.resetFields();
            }}
            title={
                <span className="text-lg">
                    {!isEditMode
                        ? `${t('permissions.pos_service_create')}`
                        : `${t('permissions.pos_service_edit')}`}
                </span>
            }
            extra={<SaveButton {...saveButtonProps} />}
        >
            {!isEditMode ? (
                <Create
                    breadcrumb={null}
                    headerProps={{ backIcon: null }}
                    title={<></>}
                    isLoading={!loading ? false : true}
                    // saveButtonProps={saveButtonProps}
                    footerButtons={<></>}
                >
                    {form}
                </Create>
            ) : (
                <Edit
                    breadcrumb={null}
                    title={<></>}
                    isLoading={loading || !initialValues?.id ? true : false}
                    footerButtons={<></>}
                    headerProps={{ backIcon: null, extra: null }}
                >
                    {form}
                </Edit>
            )}
        </Drawer>
    ) : (
        <></>
    );
};
