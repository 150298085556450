import { PriceFormItem } from '@components/price-form-item';
import { DATE_FORMAT_MINUTE } from '@constants/index.constant';
import { Currency } from '@enums/currency.enum';
import { IMarketplace } from '@interfaces/marketplace';
import { ITenant } from '@interfaces/tenant';
import {
    CrudFilters,
    HttpError,
    LogicalFilter,
    useCreate,
    useList,
    useNavigation,
    usePermissions,
} from '@refinedev/core';
import { Button, Col, DatePicker, Form, Input, notification, Row, Select, Spin } from 'antd';
import { ApplicationItem } from 'components/marketplace/application-item';
import { SelectTenant } from 'components/modules/select-tenant';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CreateAppSubscription: React.FC = () => {
    const formFields = {
        tenantId: 'tenantId',
        applicationId: 'applicationId',
        defaultPlanId: 'defaultPlanId',
        planName: 'planName',
        planPrice: 'planPrice',
        startDate: 'startDate',
        endDate: 'endDate',
    };
    const { t: translate } = useTranslation(['common', 'marketplace']);
    const { goBack } = useNavigation();
    const [filters, setFilters] = useState<CrudFilters>([]);
    const [selectedApp, setSelectedApp] = useState<IMarketplace.IApplication>();
    const [planFilters, setPlanFilters] = useState<CrudFilters>([]);
    const [form] = Form.useForm<Record<keyof typeof formFields, string | number | dayjs.Dayjs>>();
    const { data: userRights } = usePermissions<string[]>();
    const { mutate: createAction, isLoading: isCreating } = useCreate();

    const [pricePlan, setPricePlan] = useState<number>(0);
    const [currencyConfig, setCurrencyConfig] = useState<{
        prefix: string;
        currency: Currency;
    }>({
        prefix: '$',
        currency: Currency.USD,
    });

    const {
        data: applicationData,
        isLoading: isLoadingApp,
        refetch: loadActiveApps,
    } = useList<IMarketplace.IApplication, HttpError>({
        resource: `v1/tenant/active-applications`,
        config: {
            filters,
            hasPagination: false,
        },
        queryOptions: {
            enabled: false,
        },
    });

    const {
        data: planData,
        isLoading: isLoadingPlans,
        refetch: loadPlans,
    } = useList<IMarketplace.IApplicationPlan, HttpError>({
        resource: `v1/application/available-plans`,
        config: {
            filters: planFilters,
            hasPagination: false,
        },
        queryOptions: {
            enabled: false,
        },
    });

    const plans = planData?.data || [];

    const onFinish = () => {
        const payload = form.getFieldsValue();
        createAction(
            {
                resource: 'v1/tenant/manual-subscription',
                values: {
                    ...payload,
                    planPrice: Number(payload.planPrice),
                    extraPlanId: payload.defaultPlanId,
                    startDate: dayjs(payload.startDate, { utc: true }).toDate(),
                    endDate: dayjs(payload.endDate, { utc: true }).toDate(),
                },
                successNotification: {
                    message: translate('subscriptions.messages.create_success'),
                    type: 'success',
                },
                errorNotification: false,
            },
            {
                onSuccess: () => {
                    goBack();
                },

                onError: (err: HttpError) => {
                    if (err?.message === 'subscription_existed') {
                        notification.error({
                            message: translate('subscriptions.messages.subscription_existed'),
                        });
                        return;
                    }

                    notification.error({
                        message: translate('subscriptions.messages.create_subscription_failed'),
                    });
                },
            },
        );
    };

    const onClearSelectTenant = () => {
        form.resetFields();
    };

    const onSelectedTenant = (tenant?: ITenant.ITenantInfor) => {
        form.resetFields(
            Object.values(formFields).filter((value) => value !== formFields.tenantId),
        );
        if (!tenant) return;

        form.setFieldsValue({ tenantId: tenant.id });

        const newTenantFilter = {
            field: formFields.tenantId,
            operator: 'eq',
            value: tenant.id,
        } as LogicalFilter;

        const newFilters = [
            ...(filters || []).filter(
                (_filter) => (_filter as LogicalFilter).field !== formFields.tenantId,
            ),
            newTenantFilter,
        ];

        setFilters(newFilters);
    };

    useEffect(() => {
        if (filters?.length) {
            loadActiveApps();
        }
    }, [filters]);

    const onChangePlan = (planId?: string) => {
        if (!planId) {
            form.resetFields(
                Object.values(formFields).filter(
                    (value) =>
                        ![
                            formFields.tenantId,
                            formFields.applicationId,
                            formFields.defaultPlanId,
                        ].includes(value),
                ),
            );
        } else {
            const plan = (plans || []).find((plan) => plan.id === planId);
            if (plan) {
                form.setFieldsValue({
                    planName: plan.title,
                    startDate: dayjs(),
                    planPrice: !isNaN(Number(plan.pricing))
                        ? Number(plan.pricing / 100).toFixed(2)
                        : undefined,
                    endDate:
                        plan.period && plan.frequency
                            ? dayjs().add(plan.period, plan.frequency)
                            : undefined,
                    defaultPlanId: plan.id,
                });
            }
        }
    };

    const onSelectApplication = (applicationId: string) => {
        if (!applicationId) return;
        form.resetFields(
            Object.values(formFields).filter(
                (value) => ![formFields.tenantId, formFields.applicationId].includes(value),
            ),
        );
        form.setFieldsValue({
            applicationId,
        });

        if (applicationId) {
            const newPlanFilter = {
                field: 'applicationId',
                operator: 'eq',
                value: applicationId,
            } as LogicalFilter;

            const newFilters = [
                ...(planFilters || []).filter(
                    (_filter) => (_filter as LogicalFilter).field !== formFields.applicationId,
                ),
                newPlanFilter,
            ];

            setPlanFilters(newFilters);

            const _selectedApp = (applicationData?.data || []).find(
                (app) => app.id === applicationId,
            ) as IMarketplace.IApplication;
            if (_selectedApp) {
                setSelectedApp(_selectedApp);
            }
        }
    };

    useEffect(() => {
        if (planFilters?.length) {
            loadPlans();
        }
    }, [planFilters]);

    const disableInvalidEndDate = (current: dayjs.Dayjs): boolean => {
        if (!current) return false;

        const startDate = form.getFieldValue('startDate');
        return (
            dayjs(current, { utc: true }).startOf('day') <=
            (startDate
                ? dayjs(startDate, { utc: true }).startOf('day')
                : dayjs(new Date(), { utc: true }).startOf('day'))
        );
    };

    const disableInvalidStartDate = (current: dayjs.Dayjs) => {
        return current < dayjs().startOf('day');
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        const validPattern = reg.test(inputValue);
        if (validPattern) {
            setPricePlan(Number(inputValue));
        } else {
            form.setFieldsValue({
                planPrice: pricePlan,
            });
        }
    };

    return (
        <>
            <Spin spinning={isCreating}>
                <section className="create-subscription-container">
                    <div className="block-heading">
                        <span>{translate('subscriptions.create_subscription')}</span>
                        <div className="header-actions">
                            <Button onClick={goBack}>
                                {translate('subscriptions.action.cancel')}
                            </Button>
                            <Button onClick={() => form.submit()} type="primary">
                                {translate('subscriptions.action.create')}
                            </Button>
                        </div>
                    </div>
                    <Form layout="vertical" form={form} onFinish={onFinish}>
                        <div className="section">
                            <div className="section-header">
                                {translate('subscriptions.subscription_information')}
                            </div>
                            <Row gutter={20}>
                                <Col md={11}>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label={translate('subscriptions.tenant')}
                                                name={formFields.tenantId}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: translate(
                                                            'subscriptions.messages.please_select_a_tenant',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <SelectTenant
                                                    onSelectedTenant={onSelectedTenant}
                                                    clearTenant={onClearSelectTenant}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Applications"
                                                name={formFields.applicationId}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select an application',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    options={(applicationData?.data || []).map(
                                                        (app) => ({
                                                            label: <span>{app.title}</span>,
                                                            key: app.id,
                                                            value: app.id,
                                                        }),
                                                    )}
                                                    placeholder="Select applications"
                                                    loading={isLoadingApp && !!filters?.length}
                                                    disabled={!filters?.length}
                                                    onChange={onSelectApplication}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label={translate('subscriptions.default_plan')}
                                                name={formFields.defaultPlanId}
                                            >
                                                <Select
                                                    options={(plans || []).map((plan) => ({
                                                        label: <span>{plan.title}</span>,
                                                        key: plan.id,
                                                        value: plan.id,
                                                    }))}
                                                    placeholder={translate(
                                                        'subscriptions.select_plan',
                                                    )}
                                                    loading={
                                                        isLoadingPlans && !!planFilters?.length
                                                    }
                                                    disabled={!planFilters?.length}
                                                    onChange={onChangePlan}
                                                    allowClear
                                                    onClear={onChangePlan}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={2}></Col>
                                <Col md={11}>
                                    {selectedApp ? (
                                        <ApplicationItem
                                            application={selectedApp}
                                            permission={userRights || []}
                                            ignoreFooter={true}
                                            customClasses="auto-container"
                                        />
                                    ) : null}
                                </Col>
                            </Row>

                            <Row gutter={20}>
                                <div className="plan-service-wrapper section-item">
                                    <Col span={24} className="plan-service-header">
                                        <p className="plan-title mb-3">
                                            {translate('marketplace.plan_packages', {
                                                ns: 'marketplace',
                                            })}
                                        </p>

                                        <Row>
                                            <Col md={11}>
                                                <Form.Item
                                                    label={translate('subscriptions.name')}
                                                    name={formFields.planName}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate(
                                                                'subscriptions.messages.please_enter_plan_name',
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col md={2}></Col>
                                            {/* Price form item */}
                                            <Col md={11}>
                                                <PriceFormItem
                                                    label={translate('subscriptions.price')}
                                                    name={formFields.planPrice}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate(
                                                                'subscriptions.messages.please_enter_price',
                                                            ),
                                                        },
                                                    ]}
                                                    currencyConfig={currencyConfig}
                                                    pricePlan={pricePlan}
                                                    handlePriceChange={handlePriceChange}
                                                    setCurrencyConfig={setCurrencyConfig}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={11}>
                                                <Form.Item
                                                    label={translate('subscriptions.start_date')}
                                                    name={formFields.startDate}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate(
                                                                'subscriptions.messages.please_select_start_date',
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        getPopupContainer={(trigger) =>
                                                            trigger?.parentNode as HTMLElement
                                                        }
                                                        disabledDate={(current) =>
                                                            disableInvalidStartDate(current)
                                                        }
                                                        onChange={() =>
                                                            form.resetFields(['endDate'])
                                                        }
                                                        format={DATE_FORMAT_MINUTE}
                                                        showHour
                                                        showMinute
                                                        showTime={{ format: 'HH:mm' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={2}></Col>
                                            <Col md={11}>
                                                <Form.Item
                                                    label={translate('subscriptions.end_date')}
                                                    name={formFields.endDate}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate(
                                                                'subscriptions.messages.please_select_end_date',
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        getPopupContainer={(trigger) =>
                                                            trigger?.parentNode as HTMLElement
                                                        }
                                                        disabledDate={(current) =>
                                                            disableInvalidEndDate(current)
                                                        }
                                                        format={DATE_FORMAT_MINUTE}
                                                        showHour
                                                        showMinute
                                                        showTime={{ format: 'HH:mm' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            </Row>
                        </div>
                    </Form>
                </section>
            </Spin>
        </>
    );
};
