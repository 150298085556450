import './styles.scss';

import { ModulesBanner } from '@components/subscription-plan/modules-banner';
import { IMarketplace } from '@interfaces/marketplace';
import { Tabs, TabsProps } from 'antd';
import { IntroduceIndex } from 'pages/dashboard/introduce';
import { FC } from 'react';

export const DefaultDashboard: FC<{
    items: TabsProps['items'];
    applications: IMarketplace.IApplication[];
    onChangeOpenModal: (value: boolean, module?: IMarketplace.IApplication) => void;
}> = (props) => {
    const { items, applications, onChangeOpenModal } = props;

    return (
        <>
            {items?.length ? (
                <section className="default-dashboard">
                    <div className="report rounded border p-5">
                        <Tabs defaultActiveKey="1" items={items} />
                    </div>

                    <ModulesBanner
                        onChangeOpenModal={onChangeOpenModal}
                        applications={applications}
                    />
                </section>
            ) : (
                <IntroduceIndex />
            )}
        </>
    );
};
