import './styles.scss';

import { CustomModal } from '@components/modules/modal-custom';
import { PERMISSIONS } from '@constants/permission';
import { NApplicationPrice } from '@interfaces/application-price';
import { NSubscriptionPlan } from '@interfaces/subcription-plan';
import {
    BaseKey,
    HttpError,
    useApiUrl,
    useCustomMutation,
    useOne,
    usePermissions,
} from '@refinedev/core';
import { validPermission } from '@utils/resource';
import { notification } from 'antd';
import { isBoolean } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APPLICATIONS } from '../../constants/index.constant';
import Response from '../../data-access/responses/response';
import { IMarketplace } from '../../interfaces/marketplace';
import { PriceCalculatorForm } from './forms/price-calculator-form';
import { SubPlanForm } from './forms/sub-plan-form';

type UpdateTenantPlan = {
    id?: string;
    applicationId: string;
    quantity?: number;
    referralCode?: string;
};

const MAX_QUANTITY = 10000;

enum ModuleTypeEnum {
    TAX = 'tax',
    PAYGATE = 'paygate',
}

const getModalCase = (
    applicationId: string,
): { activeCase: boolean; moduleType: ModuleTypeEnum } => {
    const { data: permissions } = usePermissions<string[]>();
    let activeCase = false;
    let moduleType = ModuleTypeEnum.PAYGATE;
    switch (applicationId) {
        case APPLICATIONS.PAYGATE.id: {
            if (validPermission(permissions || [], PERMISSIONS.PAYGATE_LIST)) {
                activeCase = true;
            }
            break;
        }
        case APPLICATIONS.TAX.id: {
            moduleType = ModuleTypeEnum.TAX;

            if (validPermission(permissions || [], PERMISSIONS.TAX_LIST)) {
                activeCase = true;
            }
            break;
        }
    }
    return { activeCase, moduleType };
};

const PaymentForm: FC<{
    applicationId: string;
    quantity: number;
    onSubmit: () => void;
    onChangeQuantity: (value: number) => void;
    loading: boolean;
    onChangeReferralCode: (value: string) => void;
}> = (props) => {
    const { t } = useTranslation('common');
    const { quantity, onSubmit, onChangeQuantity, applicationId, loading, onChangeReferralCode } =
        props;
    const { activeCase, moduleType } = getModalCase(applicationId);
    const appCase = activeCase ? 'used_case' : 'unused_case';
    const buttonTitle =
        !activeCase && moduleType === ModuleTypeEnum.PAYGATE ? 'try_now' : 'payment';

    const [applicationPricesThresholds, setApplicationPricesThresholds] = useState<
        NSubscriptionPlan.PriceThreshold[]
    >([]);

    const [selectOptions, setSelectOptions] = useState<Array<{ label: string; value: number }>>([]);

    const { data: applicationPrices } = useOne<NApplicationPrice.IApplicationPrice[]>({
        resource: 'v1/application-price',
        id: applicationId as BaseKey,
        queryOptions: {
            enabled: !!applicationId,
        },
    });

    useEffect(() => {
        if (applicationPrices?.data?.length) {
            const prices: NSubscriptionPlan.PriceThreshold[] = applicationPrices?.data.map((i) => ({
                threshold: i.quantity,
                price: +i.pricePerTier,
                step: i.step,
                minQuantity: i.minQuantity,
                discountPercentage: i.discountPercentage,
            }));

            if (applicationId === APPLICATIONS.TAX.id) {
                const requiredOptions = prices
                    .filter((i) => i.threshold <= MAX_QUANTITY)
                    .map((item) => ({
                        label: item.threshold?.toString(),
                        value: item.threshold,
                    }));

                setSelectOptions(requiredOptions);
            }

            setApplicationPricesThresholds(prices);
        }
    }, [applicationPrices?.data]);

    const onChangeSelectOptions = (value: { label: string; value: number }) => {
        setSelectOptions((prev) => [...prev, value]);
    };

    const renderDescriptionList = () => (
        <div className="mt-6">
            <p className="font-semibold text-base mb-1">{t('modules_banner.title_received')}</p>
            <ul className="listed">
                <li className="my-1">
                    {t(`modules_banner.${moduleType}.${appCase}.modal.sub_description_1`)}
                </li>
                <li>{t(`modules_banner.${moduleType}.${appCase}.modal.sub_description_2`)}</li>
            </ul>
        </div>
    );

    let priceThresholds: NSubscriptionPlan.PriceThreshold[];
    switch (moduleType) {
        case ModuleTypeEnum.PAYGATE:
            priceThresholds = applicationPricesThresholds || [];

            break;

        case ModuleTypeEnum.TAX:
            priceThresholds = applicationPricesThresholds || [];
            break;
    }

    return (
        <SubPlanForm
            loading={loading}
            title={t(`modules_banner.${moduleType}.${appCase}.modal.title`)}
            description={t(`modules_banner.${moduleType}.${appCase}.modal.description`)}
            descriptionList={renderDescriptionList()}
            submitText={t(`modules_banner.actions.${buttonTitle}`)}
            onSubmit={onSubmit}
            onChangeReferralCode={onChangeReferralCode}
            formSections={
                !activeCase && moduleType === ModuleTypeEnum.PAYGATE
                    ? []
                    : [
                          {
                              title: t(`modules_banner.form.input_quantity_title`),
                              content: (
                                  <PriceCalculatorForm
                                      quantity={quantity}
                                      onChangeQuantity={onChangeQuantity}
                                      priceThresholds={priceThresholds}
                                      translations={{
                                          quantity: t(`modules_banner.form.transaction`),
                                          currency: t(`modules_banner.form.currency`),
                                          total: t(`modules_banner.form.total`),
                                      }}
                                      selectOptions={
                                          moduleType === ModuleTypeEnum.TAX
                                              ? selectOptions
                                              : undefined
                                      }
                                      onChangeSelectOptions={
                                          moduleType === ModuleTypeEnum.TAX
                                              ? onChangeSelectOptions
                                              : undefined
                                      }
                                  />
                              ),
                          },
                      ]
            }
        />
    );
};

export const ModuleItemModal: FC<{
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    registrationId?: string;
    item: IMarketplace.IApplication;
    handleOpenQrPayment: (data: {
        totalPayment: number;
        url: string;
        subscriptionOrderId: string;
        title: string;
    }) => void;
}> = ({ isOpen, onClose, item, registrationId, handleOpenQrPayment }) => {
    const { t } = useTranslation('common');
    const [transactionCount, setTransactionCount] = useState<number>(0);
    const [referralCode, setReferralCode] = useState<string>();

    const apiUrl = useApiUrl();
    const { mutate: upgradeModule, isLoading: loadingUpgradeModule } = useCustomMutation<
        Response<{ qrUrl: string; subscriptionOrderId: string } | boolean>,
        HttpError,
        UpdateTenantPlan
    >();

    const onChangeReferralCode = (value: string) => {
        setReferralCode(value);
    };

    const moduleRegistration = () => {
        upgradeModule(
            {
                url: `${apiUrl}/v1/registration/upgrade-transaction`,
                method: 'post',
                values: {
                    id: registrationId ?? undefined,
                    applicationId: item.id,
                    quantity: transactionCount,
                    referralCode,
                },
                errorNotification: false,
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    const code = error?.response?.data?.error || 'unknown';
                    notification.error({
                        className: 'error-notification',
                        message: '',
                        description: t(`plan_form.errors.${code}`),
                    });
                    console.error(error);
                },
                onSuccess: (data) => {
                    const result = data?.data;
                    if (result) {
                        if (isBoolean(result.data)) {
                            notification.success({
                                message: t('modules_banner.register_success'),
                                description: t('notifications.refresh_token'),
                            });
                        }
                        const { qrUrl, subscriptionOrderId, paymentAmount } = result.data as {
                            qrUrl: string;
                            paymentAmount: number;
                            subscriptionOrderId: string;
                        };
                        if (qrUrl)
                            handleOpenQrPayment({
                                url: qrUrl,
                                subscriptionOrderId,
                                totalPayment: paymentAmount,
                                title: t('module_banner.payment_success'),
                            });
                        onClose();
                    }
                },
            },
        );
    };

    const onChangeQuantity = (value: number) => {
        setTransactionCount(value);
    };

    return (
        <CustomModal
            open={isOpen}
            footer={false}
            closable={!loadingUpgradeModule}
            onCancel={onClose}
            maskClosable={false}
            width={630}
            className="modules-modal"
        >
            <PaymentForm
                loading={loadingUpgradeModule}
                quantity={transactionCount}
                onChangeQuantity={onChangeQuantity}
                onSubmit={moduleRegistration}
                applicationId={item.id}
                onChangeReferralCode={onChangeReferralCode}
            />
        </CustomModal>
    );
};
