import { MQTT_TOPIC } from '@constants/mqtt-topic';
// import { useSubscription } from '@nuvioo/core-ui';
import { useSubscription } from '@zodinet-pos/core-ui';
import { memo, useEffect, useMemo } from 'react';

export const Subscribe = memo(
    ({ onMessage, currentUser }: { onMessage: (message: string) => void; currentUser: string }) => {
        const topic = `${MQTT_TOPIC.REPORT}_${currentUser}`;
        const { message, onRemoveClient } = useSubscription.default(`${topic}`);

        useEffect(() => {
            return () => {
                // Ensure that onRemoveClient is called when the component is unmounted or when the user changes
                if (onRemoveClient) {
                    onRemoveClient();
                }
            };
        }, [currentUser, onRemoveClient]);
        useMemo(() => {
            if (message?.message) {
                onMessage(message?.message?.toString());
            }
        }, [message?.message]);

        return <></>;
    },
);
