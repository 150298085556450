import { NSubscriptionPlan } from '@interfaces/subcription-plan';
import { useCallback } from 'react';

interface UsePriceCalculatorProps {
    priceThresholds: NSubscriptionPlan.PriceThreshold[];
    defaultPrice?: number;
    defaultThreshold?: number;
}

export const usePriceCalculator = ({ priceThresholds }: UsePriceCalculatorProps) => {
    const calculatePrice = useCallback(
        (value: number): NSubscriptionPlan.PriceThreshold => {
            if (!priceThresholds?.length)
                return { price: 0, step: 0, minQuantity: 0, threshold: 0 };

            const threshold = priceThresholds.find((t) => value >= t.threshold);

            if (!threshold) return priceThresholds[priceThresholds.length - 1];

            return threshold;
        },
        [priceThresholds],
    );

    const calculateTaxPrice = useCallback(
        (value: number): NSubscriptionPlan.PriceThreshold => {
            if (!priceThresholds?.length)
                return { price: 0, step: 0, minQuantity: 0, threshold: 0 };

            if (value === 0) return priceThresholds[priceThresholds.length - 1];

            if (value > 10000) {
                const threshold = priceThresholds.find((t) => {
                    return value > t?.threshold;
                });

                if (threshold) {
                    return threshold;
                }
            }

            const threshold = priceThresholds.find((t) => value == t.threshold);
            if (!threshold) return priceThresholds[priceThresholds.length - 1];

            return threshold;
        },
        [priceThresholds],
    );

    const getThresholdByPrice = useCallback(
        (value: number): NSubscriptionPlan.PriceThreshold | null => {
            return priceThresholds.find((t) => value == t.price) ?? null;
        },
        [priceThresholds],
    );

    const calculateTotal = useCallback(
        (quantity: number): { total: number; discountedTotal?: number } => {
            const price = calculatePrice(quantity);
            const total = quantity * price?.price;

            if (price?.discountPercentage) {
                const discount = (price?.discountPercentage / 100) * total;
                return {
                    total,
                    discountedTotal: total - discount,
                };
            }

            return { total };
        },
        [calculatePrice],
    );

    const calculateTaxTotal = useCallback(
        (quantity: number): { total: number; discountedTotal?: number } => {
            const price = calculateTaxPrice(quantity);
            const total = quantity * price?.price;

            if (price?.discountPercentage) {
                const discount = (price?.discountPercentage / 100) * total;
                return {
                    total,
                    discountedTotal: total - discount,
                };
            }

            return { total };
        },
        [calculateTaxPrice],
    );

    return {
        calculatePrice,
        calculateTotal,
        calculateTaxTotal,
        calculateTaxPrice,
        getThresholdByPrice,
    };
};
