import './styles.scss';

import { DATE_FORMAT } from '@constants/index.constant';
import { IMarketplace } from '@interfaces/marketplace';
import { ISubscription } from '@interfaces/subscription';
import { ShowButton, useModal, useTable } from '@refinedev/antd';
import { useNavigation } from '@refinedev/core';
import { convertPrice } from '@utils/resource';
import { Button, Dropdown, Menu, Modal, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    formatAppSubscriptionPeriodEnd,
    formatDate,
    RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
} from 'utils/date';

import BillingDetail from './billing-detail';

export const BillingSubscriptionIndex: React.FC = () => {
    const { t: translate } = useTranslation(['common']);
    const { id } = useParams();
    const { goBack, push } = useNavigation();
    const [billingId, setBillingId] = useState('');
    const componentToPrint = useRef<any>();
    const [invoiceIncrement, setInvoiceIncrement] = useState<any>();
    const { tableProps } = useTable<ISubscription.SubscriptionList>({
        resource: `v1/sale-subscription/billing/${id}`,
        hasPagination: false,
    });

    useEffect(() => {
        if (tableProps?.dataSource?.length) {
            setInvoiceIncrement(tableProps.dataSource[0].invoice?.incrementId);
        }
    }, [tableProps?.dataSource]);

    const { modalProps, show, close } = useModal();
    const handleShowBilling = (id?: string) => {
        if (id) {
            setBillingId(id);
        }
        show();
    };
    return (
        <>
            <section className="item-list-container billing-subscription-page-wrapper ">
                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            {...tableProps}
                            rowKey="id"
                            ref={(el) => (componentToPrint.current = el)}
                        >
                            <Table.Column
                                title={<>{translate('subscriptions.createdAt')}</>}
                                dataIndex="createdAt"
                                key="createdAt"
                                render={(text, _) => {
                                    return (
                                        <p className="table-tbody-text">
                                            {formatDate(text, DATE_FORMAT)}
                                        </p>
                                    );
                                }}
                            />
                            <Table.Column
                                title={<>{translate('subscriptions.periodStart')}</>}
                                dataIndex="periodStart"
                                key="periodStart"
                                render={(text, _) => {
                                    return (
                                        <p className="table-tbody-text">
                                            {formatDate(text, DATE_FORMAT)}
                                        </p>
                                    );
                                }}
                            />
                            <Table.Column
                                title={<>{translate('subscriptions.periodEnd')}</>}
                                dataIndex="periodEnd"
                                key="periodEnd"
                                render={(periodEnd, record: IMarketplace.ISubscriptionInvoice) => {
                                    return (
                                        <p className="table-tbody-text">
                                            {formatAppSubscriptionPeriodEnd(
                                                RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
                                                periodEnd as Date,
                                                record,
                                            )}
                                        </p>
                                    );
                                }}
                            />
                            <Table.Column
                                title={<>{translate('subscriptions.netAmount')}</>}
                                dataIndex="netAmount"
                                key="netAmount"
                                render={(text, _) => {
                                    return <p className="table-tbody-text">{convertPrice(text)}</p>;
                                }}
                            />
                            <Table.Column
                                title={<>{translate('subscriptions.paymentFee')}</>}
                                dataIndex="paymentFee"
                                key="paymentFee"
                                render={(text, _) => {
                                    return <p className="table-tbody-text">{convertPrice(text)}</p>;
                                }}
                            />

                            <Table.Column
                                dataIndex="id"
                                key="action"
                                render={(_, record: any, __) => (
                                    <div className="flex justify-end items-center">
                                        <Dropdown
                                            overlay={
                                                <Menu mode="vertical">
                                                    <Menu.Item
                                                        key="1"
                                                        icon={
                                                            <ShowButton
                                                                recordItemId={id}
                                                                style={{
                                                                    padding: '5px 16px 5px 12px',
                                                                    margin: '0px',
                                                                }}
                                                                onClick={() => {
                                                                    handleShowBilling(record?.id);
                                                                }}
                                                            />
                                                        }
                                                        style={{ padding: 0 }}
                                                    ></Menu.Item>
                                                </Menu>
                                            }
                                            placement="bottomRight"
                                            arrow
                                            overlayClassName="invoice-dropdown-container"
                                            trigger={['click']}
                                        >
                                            <img
                                                src="/images/icons/dots-vertical.svg"
                                                alt="more"
                                                className="cursor-pointer"
                                            />
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </Table>
                    </div>
                </div>
            </section>
            <Modal
                {...modalProps}
                title="Billing Detail"
                maskClosable={false}
                footer={null}
                width={1200}
                wrapClassName="payment-modal"
            >
                {billingId && (
                    <div className="mt-2">
                        <BillingDetail
                            billingId={billingId}
                            onCancel={() => {
                                close();
                            }}
                        />
                    </div>
                )}
            </Modal>

            <div className="go-page-list flex justify-end pt-6">
                <Button onClick={goBack}>{translate('buttons.cancel')}</Button>
            </div>
        </>
    );
};
