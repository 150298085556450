import './styles.scss';

import { FormOutlined } from '@ant-design/icons';
import { PERMISSIONS } from '@constants/permission';
import { DeleteButton, useModal } from '@refinedev/antd';
import {
    HttpError,
    useCustom,
    useCustomMutation,
    usePermissions,
    useTranslate,
} from '@refinedev/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Dropdown, Menu, Modal, Spin, Table, Tag } from 'antd';
import Response from 'data-access/responses/response';
import { getEnvConfig } from 'getEnvConfig';
import React, { useState } from 'react';

import { Payment } from '../../../interfaces/payment';
import PaymentForm from './payment-form';

interface IBillingPaymentMethodProps {
    [name: string]: string;
}

export const BillingPaymentMethod: React.FC<IBillingPaymentMethodProps> = () => {
    const stripePromise = loadStripe(getEnvConfig.STRIPE_PUBLIC_KEY);
    const t = useTranslate();
    const apiUrl = getEnvConfig.PORTAL_URL;

    const { data: permissions } = usePermissions<string[]>();

    const { mutate: createPaymentIntent, isLoading: isLoadingCreatePaymentIntent } =
        useCustomMutation<Response<{ clientSecret: string }>, HttpError, any>();

    const { mutate: setDefaultPayment, isLoading: isLoadingSetDefaultPayment } = useCustomMutation<
        Response<boolean>,
        HttpError,
        any
    >();

    const {
        data: paymentMethods,
        refetch: paymentRefetch,
        isLoading: getPaymentMethodStatus,
    } = useCustom<Payment.IPaymentMethod[]>({
        url: `${apiUrl}/v1/users/payment-methods`,
        method: 'get',
    });

    const [clientSecret, setClientSecret] = useState<string | null>(null);

    const { modalProps, show, close } = useModal({
        modalProps: {
            onCancel: () => {
                setClientSecret(null);
            },
        },
    });

    const onPaymentIntentSuccess = () => {
        // close modal reload subscriptions
        close();
        setClientSecret(null);
    };

    const handleAddNewCard = () => {
        show();
        createPaymentIntent(
            {
                url: `${apiUrl}/v1/users/payment-intent`,
                method: 'post',
                values: {},
            },
            {
                onError: (error) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (data) => {
                    const result = data?.data;
                    setClientSecret(result.data.clientSecret);
                },
            },
        );
    };

    const handleSetDefaultPayment = async (cardId: string) => {
        setDefaultPayment(
            {
                url: `${apiUrl}/v1/users/payment-methods/${cardId}/set-default`,
                method: 'put',
                values: {},
            },
            {
                onError: (error) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: async (data) => {
                    if (data.data) {
                        await paymentRefetch();
                    }
                },
            },
        );
    };

    return (
        <>
            <div className="mb-3">
                <h2>{t('billing.payment_methods', 'Payment methods')}</h2>
            </div>
            <Table
                rowKey="id"
                showHeader={false}
                dataSource={paymentMethods?.data || []}
                pagination={false}
                loading={getPaymentMethodStatus}
                scroll={{ x: '576px' }}
            >
                <Table.Column
                    dataIndex="brand"
                    key="brand"
                    sorter
                    render={(brand, record: Payment.IPaymentMethod, index) => (
                        <div className="flex justify-between items-center ">
                            <div className="flex justify-start items-center">
                                <div className="card-img ml-auto">
                                    <img
                                        src={`/images/payments/${record.brand?.toLowerCase()}.svg`}
                                    />
                                </div>
                                <p className="table-tbody-text ml-2 item-name pd-0 w-52">
                                    {brand} {t('billing.ending_in', 'ending in')} {record.last4}
                                </p>
                            </div>
                        </div>
                    )}
                />
                <Table.Column
                    dataIndex="expMonth"
                    key="expMonth"
                    render={(text, record: Payment.IPaymentMethod, index) => (
                        <p className="table-tbody-text">
                            {t('billing.expires', 'Expires')} {record.expMonth}/{record.expYear}
                        </p>
                    )}
                />
                <Table.Column
                    dataIndex="isDefault"
                    key="isDefault"
                    render={(isDefault, record: Payment.IPaymentMethod, index) => (
                        <p className="table-tbody-text">
                            {isDefault ? <Tag>{t('billing.default', 'Default')}</Tag> : null}
                        </p>
                    )}
                />
                <Table.Column
                    dataIndex="id"
                    key="action"
                    render={(id, record: Payment.IPaymentMethod, index) => (
                        <div className="flex justify-end items-center" style={{ minWidth: '65px' }}>
                            <Dropdown
                                overlay={
                                    <Menu mode="vertical">
                                        {!record.isDefault &&
                                        (permissions || []).includes(PERMISSIONS.BILLING_CREATE) ? (
                                            <Menu.Item
                                                key="1"
                                                style={{
                                                    fontWeight: 500,
                                                }}
                                                icon={
                                                    <FormOutlined
                                                        style={{
                                                            color: 'green',
                                                        }}
                                                    />
                                                }
                                                onClick={() =>
                                                    handleSetDefaultPayment(record?.id as string)
                                                }
                                            >
                                                {t('buttons.makeDefault')}
                                            </Menu.Item>
                                        ) : null}
                                        {(permissions || []).includes(
                                            PERMISSIONS.BILLING_DELETE,
                                        ) ? (
                                            <Menu.Item key="3">
                                                <DeleteButton
                                                    successNotification={{
                                                        message: 'Successfully deleted',
                                                        type: 'success',
                                                    }}
                                                    resource="v1/users/payment-methods"
                                                    recordItemId={id}
                                                    onSuccess={() => {
                                                        paymentRefetch();
                                                    }}
                                                    disabled={record.isDefault}
                                                    className="delete__action"
                                                />
                                            </Menu.Item>
                                        ) : null}
                                    </Menu>
                                }
                                placement="bottomRight"
                                arrow
                                overlayClassName="contact-dropdown-container"
                                trigger={['click']}
                            >
                                <img
                                    src="/images/icons/dots-vertical.svg"
                                    alt="more"
                                    className="cursor-pointer"
                                />
                            </Dropdown>
                        </div>
                    )}
                />
            </Table>
            {(permissions || []).includes(PERMISSIONS.BILLING_CREATE) ? (
                <div className="my-2 flex justify-end">
                    <Button type="primary" onClick={handleAddNewCard}>
                        {`${t('payouts.add_new_card', { ns: 'common' })}`}
                    </Button>
                </div>
            ) : null}
            <Modal
                {...modalProps}
                title="Payment"
                maskClosable={false}
                footer={null}
                width={650}
                wrapClassName="payment-modal"
            >
                {clientSecret && (
                    <Elements
                        stripe={stripePromise}
                        options={{
                            clientSecret: clientSecret,
                        }}
                    >
                        <PaymentForm onSuccess={onPaymentIntentSuccess} />
                    </Elements>
                )}
                {!clientSecret && (
                    <div className="mt-2 mb-2">
                        <Spin spinning={true} />
                    </div>
                )}
            </Modal>
        </>
    );
};
